<template>
  <nav aria-label="Navigation">
    <ul class="pagination justify-content-center mb-0">
      <li :class="{ 'page-item': true, 'disabled': value <= 1 }">
        <span class="page-link" @click="$emit('input', value - 1)"><i class="fal fa-angle-left"></i></span>
      </li>
      <li class="page-item disabled">
        <span class="page-link">Page <strong>{{ value }}</strong><span v-if="pageCount"> of {{ pageCount }}</span></span>
      </li>
      <li :class="{ 'page-item': true, 'disabled': !hasMore }">
        <span class="page-link" @click="$emit('input', value + 1)"><i class="fal fa-angle-right"></i></span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    hasMore: Boolean,
    pageCount: Number,
    value: Number,
  },
}
</script>
